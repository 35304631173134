<template>
  <b-sidebar
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          ساخت ارز جدید
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="submit"
        @reset.prevent="resetValue"
      >
        <b-form-group label="نام انگلیسی">
          <b-form-input
            v-model="crypto.english_name"
            dir="ltr"
            class="text-left"
            type="text"
            trim
            placeholder="نام انگلیسی"
            :state="crypto.english_name!==''"
            required
          />
        </b-form-group>
        <b-form-group label="نام فارسی">
          <b-form-input
            v-model="crypto.persian_name"
            dir="ltr"
            class="text-left"
            type="text"
            trim
            placeholder="نام فارسی"
            :state="crypto.persian_name!==''"
            required
          />
        </b-form-group>
        <b-form-group label="نماد">
          <b-form-input
            v-model="crypto.symbol"
            dir="ltr"
            class="text-left"
            type="text"
            trim
            placeholder="BTC, USDT :نماد ارز مانند"
            :state="crypto.symbol!==''"
            required
          />
        </b-form-group>
        <b-form-group label="قیمت خرید از ما">
          <b-form-input
            v-model="crypto.purchase_price_from_us"
            dir="ltr"
            class="text-left"
            type="number"
            trim
            placeholder="قیمت خرید از ما"
            :state="crypto.purchase_price_from_us!==''"
            required
          />
        </b-form-group>
        <b-form-group label="قیمت فروش به ما">
          <b-form-input
            v-model="crypto.sale_price_from_us"
            dir="ltr"
            class="text-left"
            type="number"
            trim
            placeholder="قیمت فروش به ما"
            :state="crypto.sale_price_from_us!==''"
            required
          />
        </b-form-group>
        <b-form-group label="آدرس کیف پول">
          <b-form-input
            v-model="crypto.wallet_address"
            dir="ltr"
            class="text-left"
            type="text"
            trim
            placeholder="آدرس کیف پول"
          />
        </b-form-group>
        <b-form-group label="موجودی">
          <b-form-input
            v-model="crypto.inventory"
            dir="ltr"
            class="text-left"
            type="number"
            trim
            placeholder="موجودی"
            :state="crypto.inventory!==''"
            required
          />
        </b-form-group>
        <b-form-group label="کارمزد">
          <b-form-input
            v-model="crypto.wage"
            dir="ltr"
            class="text-left"
            type="number"
            trim
            placeholder="کارمزد"
          />
        </b-form-group>
        <b-form-group label="وضعیت نمایش ارز">
          <b-form-select
            v-model="crypto.status"
            :options="status"
            :state="crypto.status!==0"
          />
        </b-form-group>
        <b-form-group label="نوع ارز">
          <b-form-select
            v-model="crypto.type"
            :options="cryptTypeOption"
            :state="crypto.type!==null"
          />
        </b-form-group>
        <b-form-group label="عکس">
          <b-form-file
            v-model="crypto.image"
            accept="image/jpeg, image/png, image/gif"
            :state="Boolean(crypto.image)"
            size="sm"
            placeholder="عکس رمز ارز را انتخاب کنید..."
            drop-placeholder="Drop file here..."
          />
        </b-form-group>

        <!--        footer -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            ثبت ارز
          </b-button>
          <b-button
            v-ripple.400="'rgba(199,186,186,0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-2"
          >
            از نو
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            @click="hide"
          >
            بازگشت
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  // BFormInvalidFeedback,
  BButton,
  BFormSelect,
  // BInputGroupPrepend,
  // BInputGroup,
  // BInputGroupAppend,
  // BDropdown,
  // BDropdownItem,

} from 'bootstrap-vue'

export default {
  name: 'AddNewCurrency',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    // BFormInvalidFeedback,
    BButton,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      crypto: {
        english_name: '',
        persian_name: '',
        symbol: '',
        purchase_price_from_us: '',
        sale_price_from_us: '',
        wallet_address: '',
        inventory: '',
        status: 0,
        type: null,
        image: null,
        wage: '',
      },
      status: [
        { value: 0, text: 'وضعیت نمایش ارز را انتخاب کنید' },
        { value: 0, text: 'غیر فعال' },
        { value: 1, text: 'فعال' },
      ],
      cryptTypeOption: [
        { value: null, text: 'لطفا نوع رمز ارز را انتخاب کنید' },
        { value: 'crypto', text: 'رمز ارز' },
        { value: 'system_payment', text: 'سیستم پرداخت' },
      ],
    }
  },
  methods: {
    resetValue() {
      this.crypto = {
        english_name: '',
        persian_name: '',
        symbol: '',
        purchase_price_from_us: '',
        sale_price_from_us: '',
        wallet_address: '',
        inventory: '',
        status: 0,
        image: null,
        type: null,
        wage: '',
      }
    },
    async submit() {
      const data = new FormData()
      data.append('english_name', this.crypto.english_name)
      data.append('persian_name', this.crypto.persian_name)
      data.append('symbol', this.crypto.symbol)
      data.append('purchase_price_from_us', this.crypto.purchase_price_from_us)
      data.append('sale_price_from_us', this.crypto.sale_price_from_us)
      data.append('wallet_address', this.crypto.wallet_address)
      data.append('inventory', this.crypto.inventory)
      data.append('status', this.crypto.status)
      data.append('image', this.crypto.image)
      data.append('wage', this.crypto.wage)
      data.append('type', this.crypto.type)

      const response = await this.$axios({
        url: '/crypto',
        method: 'POST',
        data,
      })

      this.resetValue()

      this.$swal({
        icon: 'success',
        title: 'ارز جدید ساخت شد',
        confirmButtonText: 'تایید',
        // text: 'Your file has been deleted.',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })

      this.$emit('added')
      this.isActive = false
      // console.log(response.data)
    },
  },
}
</script>

<style scoped>

</style>
