<template>
  <div>

    <b-card>
      <div class="demo-spacing-0 d-flex justify-content-between m-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="addNewIsActive=true"
        >
          <feather-icon
            size="16"
            icon="PlusCircleIcon"
          />
          ساخت ارز جدید
        </b-button>
      </div>
    </b-card>

    <b-card
      no-body
      class="p-1"
    >
      <!--      add new crypto -->
      <add-new-currency
        :is-active.sync="addNewIsActive"
        @added="getCryptos(); getSystemPayment"
      />

      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="DiscIcon"
            size="18"
          />
          <span class="align-middle ml-50">رمز ارزها</span>
        </b-card-title>
      </b-card-header>
      <b-overlay
        :show="loading"
        rounded="sm"
      >
        <b-row>
          <b-col>
            <CryptoCurrencyTable
              :items="cryptos"
              @deleted="getCryptos(); getSystemPayment"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

    <!--    system payment section-->
    <b-card
      no-body
      class="p-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="DiscIcon"
            size="18"
          />
          <span class="align-middle ml-50">سیستم های پرداخت</span>
        </b-card-title>
      </b-card-header>
      <b-overlay
        :show="loading"
        rounded="sm"
      >
        <b-row>
          <b-col>
            <CryptoCurrencyTable :items="system_payments" />
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
  </div>

</template>

<script>
import {
  BCard,
  BOverlay,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BButton,
} from 'bootstrap-vue'
import CryptoCurrencyTable from '@/views/CryptoCurrency/CryptoCurrencyTable.vue'
import AddNewCurrency from '@/views/CryptoCurrency/AddNewCurrency'

export default {
  name: 'CryptoCurrency',
  components: {
    AddNewCurrency,
    CryptoCurrencyTable,
    BCard,
    BOverlay,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  data: () => ({
    dir: false,
    loading: false,
    coins: [],
    cryptos: [],
    system_payments: [],
    data: {},
    edit: false,
    type: false,
    addNewIsActive: false,
  }),
  async mounted() {
    await this.getCryptos()
    await this.getSystemPayment()
  },
  methods: {
    async getData() {
      // this.loading = true

      const res = await this.$axios('/crypto?type=crypto')
      // this.loading = false
      console.log(res)
      // this.items = res.data.content.slice(0, Math.ceil(res.data.content.length / 2))
      // this.items1 = res.data.content.slice(Math.ceil(res.data.content.length / 2))
    },
    async getCryptos() {
      this.loading = true
      const response = await this.$axios('/crypto?type=crypto')
      this.cryptos = response.data
      this.loading = false
    },
    async getSystemPayment() {
      this.loading = true
      const response = await this.$axios('/crypto?type=system_payment')
      this.system_payments = response.data
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
