<template>
  <span
    v-if="!edit"
    v-b-tooltip.hover="'اصلاح کارمزد'"
    class="w-100 text-right d-block cursor-pointer"
    @click="change"
  >
    {{ item.wage }}
  </span>
  <b-overlay
    v-else
    rounded
    :show="loading"
  >
    <b-form
      class="d-flex align-items-center"
      @submit.prevent="submit"
      @reset.prevent="edit=false"
    >
      <b-form-input
        v-model="data"
        dir="ltr"
        class="py-0 px-50"
        @input="data = $toLocal(data)"
      />
      <b-button
        v-tooltip="'ثبت'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn-icon rounded-circle ml-50"
        size="sm"
        variant="success"
        type="submit"
      >
        <feather-icon icon="CheckIcon" />
      </b-button>
      <b-button
        v-tooltip="'لغو'"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="btn-icon rounded-circle ml-50"
        size="sm"
        variant="outline-secondary"
        type="reset"
      >
        <feather-icon icon="XIcon" />
      </b-button>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BButton, BFormInput, BForm, BOverlay, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'CryptoCurrencyTableField',
  components: {
    BButton,
    BFormInput,
    BForm,
    BOverlay,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['item'],
  data() {
    return {
      edit: false,
      data: this.item.transferFee,
      loading: false,
    }
  },
  methods: {
    async submit() {
      this.loading = true
      const res = await this.$axios.put(`/coins/${this.item.id}`, { transferFee: this.data }).catch(() => {
      })
      this.loading = false
      if (res) {
        this.item.transferFee = this.$S2N(this.data)
        const text = `کارمزد شبکه ${this.$coinLabel[this.item.coin]} به مقدار ${this.item.transferFee} تغییر یافت `
        this.$error('', text, 'success')
        this.edit = false
      }
    },
    change() {
      this.data = this.item.transferFee
      this.edit = true
    },
  },
}
</script>

<style scoped>

</style>
