<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-table
      ref="refUserListTable"
      :items="items"
      :small="true"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="اطلاعاتی یافت نشد"
      class="text-nowrap"
      style="min-height : 235px"
    >
      <template #cell(persian_name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.image"
              :variant="`light-info`"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.persian_name }}
          </b-link>
          <small class="text-muted">{{ data.item.symbol }}</small>
        </b-media>
      </template>

      <template #head(transferFee)="{field}">
        <span class="text-right d-block w-100">
          {{ field.label }}
        </span>
      </template>

      <template #cell(transferFee)="{item}">
        <CryptoCurrencyField :item="item" />
      </template>

      <template #cell(status)="data">
        <!--      <b-badge-->
        <!--        pill-->
        <!--        :variant="statusHandler[data.item.status].color"-->
        <!--      >-->
        <!--        {{ statusHandler[data.item.status].name }}-->
        <!--      </b-badge>-->
        <div style="max-width: 200px">
          <b-form-select
            v-model="data.item.status"
            :options="options"
            :state="data.item.status !== 0"
            size="sm"
            @change="changeStatus(data.item.id)"
          />
        </div>
      </template>

      <template #cell(purchase_price_from_us)="data">
        <span dir="ltr">
          {{ Number(data.item.purchase_price_from_us).toLocaleString() }}
        </span>
      </template>

      <template #cell(sale_price_from_us)="data">
        <span dir="ltr">
          {{ Number(data.item.sale_price_from_us).toLocaleString() }}
        </span>
      </template>

      <template #cell(tommani_inventory)="data">
        <span dir="ltr">
          {{ Number(data.item.tommani_inventory).toLocaleString() }}
        </span>
      </template>

      <template #cell(inventory)="data">
        <span dir="ltr">
          {{ Number(data.item.inventory).toLocaleString() }}
        </span>
      </template>

      <template #cell(currency)="data">
        <span dir="ltr">
          {{ Number(data.item.currency).toLocaleString() }}
        </span>
      </template>

      <template #cell(wage)="data">
        <span dir="ltr">
          {{ data.item.wage || 0 }}
        </span>
      </template>

      <template #cell(action)="{item}">

        <b-dropdown
          id="dropdown-offset"
          variant="link"
          no-caret
          offset="80px"
          :right="true"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item :to="{ name: 'edit-cryptocurrency', params: { id: item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">ویرایش</span>
          </b-dropdown-item>

          <b-dropdown-item @click="deleteCrypto(item.id)">
            <feather-icon
              icon="DeleteIcon"
              badge-classes="badge-info test-badge"
            />
            <span class="align-middle ml-50">حذف</span>
          </b-dropdown-item>

        </b-dropdown>

      </template>

    </b-table>
  </b-overlay>

</template>

<script>
import {
  BAvatar,
  BLink,
  BMedia,
  BTable,
  BFormSelect,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import CryptoCurrencyField from '@/views/CryptoCurrency/CryptoCurrencyTableField.vue'

export default {
  name: 'CryptoCurrencyTable',
  components: {
    CryptoCurrencyField,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormSelect,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  props: ['items'],
  data() {
    return {
      dir: false,
      loading: false,
      columns: [
        {
          label: 'رمز ارز',
          key: 'persian_name',
          sortable: false,
        },
        {
          label: 'قیمت خرید از ما',
          key: 'purchase_price_from_us',
          sortable: true,
        },
        {
          label: 'قیمت فروش به ما',
          key: 'sale_price_from_us',
          sortable: true,
        },
        {
          label: 'موجودی',
          key: 'inventory',
          sortable: true,
        },
        {
          label: 'موجودی تومانی',
          key: 'tommani_inventory',
          sortable: true,
        },
        {
          label: 'کارمزد شبکه',
          key: 'wage',
          sortable: false,
        },
        {
          label: 'قیمت دلار',
          key: 'currency',
          sortable: false,
        },
        {
          label: 'وضعیت',
          key: 'status',
          sortable: true,
        },
        {
          label: 'مدیریت',
          key: 'action',
        },
      ],
      selected: null,
      options: [
        { value: 0, text: 'غیر فعال' },
        { value: 1, text: 'فعال' },
      ],
      edit: false,
      type: false,
      statusHandler: {
        1: { name: 'فعال', color: 'success' },
        0: { name: 'غیرفعال', color: 'danger' },
      },
    }
  },
  methods: {
    dataEntered(coin, e) {
      console.log(coin, e)
      this.edit = false
      // this.data[coin] = this.$S2N(this.$toLocal(e)) || 0
      // this.$forceUpdate()
    },
    async changeStatus(id) {
      this.loading = true
      const response = await this.$axios(
        `crypto/${id}/change-status`,
      )
      console.log(response.data)
      this.loading = false
    },
    deleteCrypto(id) {
      this.$bvModal
        .msgBoxConfirm('لطفا تایید کنید که آیا میخواهید این رمز ارز را حذف کنید؟', {
          title: 'آیا تایید میکنید؟',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'بله',
          cancelTitle: 'خیر',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.loading = true
            const response = this.$axios({
              url: `/crypto/${id}`,
              method: 'DELETE',
            })

            this.$emit('deleted')
            this.loading = false
            this.$swal({
              icon: 'success',
              title: 'عملیات با موفقیت انجام شد',
              confirmButtonText: 'تایید',
              // text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
